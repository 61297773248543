import { ethers } from "ethers";

const networkId = 137  //mainnet
// const networkId = 80001  //testnet
/*Switch to mainnet*/  const networkData = [
    {
        chainId: ethers.utils.hexlify(networkId),
        rpcUrls: ["https://polygon-rpc.com"],

        chainName: "Polygon Mainnet",
        nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC", // 2-6 characters long
            decimals: 18,
        },
        blockExplorerUrls: ["https://polygonscan.com/"],
    },
]
// /*Switch to Testnet*/  const networkData = [
//     {
//       chainId: ethers.utils.hexlify(networkId),
//       rpcUrls:[  "https://rpc-mumbai.maticvigil.com"],

//       chainName: "Matic Testnet",
//       nativeCurrency: {
//         name: "MATIC",
//         symbol: "MATIC", // 2-6 characters long
//         decimals: 18,
//       },
//       blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
//     },
//   ]
export const switchNetworks = async () => {
    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: ethers.utils.hexlify(networkId) }],
        });
    } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: networkData
                });
            } catch (addError) {
                // handle "add" error
                alert('Please switch network')
                window.location.reload()
            }
          
        }
        alert('Please switch network')
        window.location.reload()

        // handle other "switch" errors
    }

}
 export const switchProvider = async (provider)=>{
    if (provider) {
      if (provider !== window.ethereum) {
          console.error("Not window.ethereum . Do you have multiple wallets installed ?")
      }
      await provider.request({
          method: "eth_requestAccounts"
      })
      const newProvider = new ethers.providers.Web3Provider(window.ethereum)
      // const signer = newProvider.getSigner();
      // const account = await signer.getAddress()
      const network = await newProvider.getNetwork()
      // console.log( network)
      if (network.chainId !== networkId) {
          await switchNetworks()
      }
      const changedProvider = new ethers.providers.Web3Provider(window.ethereum)
      return changedProvider
  }
}
export const detectProvider = () => {
  let provider;
  if (window.ethereum) {
      provider = window.ethereum;
  } else if (window.web3) {
      provider = window.web3.currentProvider
  } else {
      window.alert("No ethereum browser detected ! check out metamask")
  }
  return provider
}