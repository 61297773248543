import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from '../Loading/Loading';
import Button from '../../UI/Button/Button';
import Logo from '../../assets/img/Logo.png';
import RegisterSuccessful from '../RegisterSuccessful/RegisterSuccessful';
import './connectWallet.css';
import { detectProvider, switchProvider } from '../../data/provider';
import axios from 'axios';
import Swal from 'sweetalert2';

const ConnectWallet = (props) => {
  const [registerPopupData, setRegisterPopupData] = useState({});
  const [isRegisterPopup, setIsRegisterPopup] = useState(false);
  const [account, setAccount] = useState('');
  const navigate = useNavigate();
  const { search } = useLocation();

  let referral = search.slice(1);
  let referalsLink = '';
  const refferalLink = (referral) => {
    if (referral.length === 0) {
      referalsLink = '';
    } else {
      referalsLink = referral;
    }
  };

  const handleCheckRegisterButton = async () => {
    const provider = detectProvider();
    if (provider) {
      const newProvider = await switchProvider(provider);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      setAccount(address);
      const referral = referalsLink;
      if (referral !== '') {
        const data = {
          accountAddress: address,
          sponserAddress: referral
        };
        const api = 'https://backendtoken.decentrawood.com/users/registerUser';
        // const api = 'http://localhost:5000/users/registerUser';
        try {
          const resp = await axios.post(`${api}`, data);
          console.log(resp.data, 'hi');
          if (resp.data.message === 'User already register') {
            // handleRegister(address);
            handleCheckRegister();
          } else {
            setRegisterPopupData({
              referredBy: resp.data.data.referredBy,
              address: resp.data.data.walletAddress
            });
            setIsRegisterPopup(true);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        Swal.fire({
          position: 'top',
          icon: 'question',
          text: 'Referral link is necessary to register'
        });
      }
    }
  };
  const handleCheckRegister = async () => {
    const provider = detectProvider();
    if (provider) {
      const newProvider = await switchProvider(provider);
      const signer = await newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      setAccount(address);
      const api = `https://backendtoken.decentrawood.com/users/buyToken/${address}`;
      // const api = `http://localhost:5000/users/buyToken/${address}`;
      try {
        const resp = await axios.get(api);
        if (resp.data.data === '') {
          Swal.fire({
            position: 'top',
            icon: 'question',
            text: 'Please Register'
          });
        } else {
          // handleConnect();
          props.setIsLogin(true);
          navigate(`/dashboard`);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    // if (props.account !== '') {
    //   navigate(`/dashboard`);
    // }
  }, [props.account]);
  return (
    <div className="container main_background connectmetamask_button">
      <Loading loading={props.loading} />
      <img src={Logo} alt="" srcSet="" />
      <Button
        name="Register"
        fun={() => {
          handleCheckRegisterButton();
          refferalLink(referral);
        }}
      />
      <div className="divStyle">
        <h3 className="headerStyle">Registered User</h3>
        <a onClick={handleCheckRegister}>Connect Wallet !</a>
        {isRegisterPopup ? (
          <RegisterSuccessful
            account={account}
            setIsRegisterPopup={setIsRegisterPopup}
            registerPopupData={registerPopupData}
            handleRegister={props.handleRegister}
            refferalLink={props.refferalLink}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ConnectWallet;
