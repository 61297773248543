// import { useNavigate } from 'react-router-dom';
import React from 'react';
import './button.css';

const Button = (props) => {
  const fun = props.fun;
  
  return (
    <button className="btn"  onClick={fun}>
      {props.name}
    </button>
  );
};

export default Button;
