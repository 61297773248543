import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from '../../assets/img/Logo.png';
import Button from '../../UI/Button/Button';
import './RegisterSuccessful.css';

function RegisterSuccessful(props) {
  const { search } = useLocation();
  const [textMessage, setTextMessage] = useState(false);
console.log(props)
  let referral = search.slice(1);
  const { registerPopupData } = props;
  const submitButtonClick = (e) => {
    e.preventDefault();
    props.handleRegister();
    props.refferalLink(referral);
    props.setIsRegisterPopup(false);
  };
  const closeButtonClick = () => {
    props.setIsRegisterPopup(false);
  };
  useEffect(() => {
    let refferalAddress = '0x4172bDBF52E74570725866f4Ab05e6288E8b10EE';
    if (registerPopupData.referredBy === refferalAddress) {
      setTextMessage(true);
    } else {
      setTextMessage(false);
    }
  }, []);
  return (
    <>
      <header className="header">
        <div className="logo">
          <div className="container buytoken">
            <form onSubmit={submitButtonClick} className="butTokenForm">
              <img src={Logo} alt="" className="formLogo" />
              <div className="box">
                <p>Connected Wallet</p>
                <input type="text" placeholder={registerPopupData.address} disabled />
              </div>
              <div className="box">
                <p>Referral Wallet</p>
                <input type="text" placeholder={registerPopupData.referredBy} disabled />
                {textMessage ? (
                  <p className="messegeStyle">
                    As your account is not referred by any user , You will be registered with
                    default referral address.
                  </p>
                ) : null}
              </div>
              <div className="buttonStyle">
                <Button name="Submit"/>
              </div>
            </form>
          </div>
        </div>
        <button className="closeBtn" onClick={closeButtonClick}>
          X
        </button>
      </header>
    </>
  );
}
export default RegisterSuccessful;
