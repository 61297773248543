import React from 'react';
import Button from '../../UI/Button/Button';
import './buytokenbutton.css';
import Logo from '../../assets/img/Logo.png';
import { useNavigate } from 'react-router-dom';

const BuyTokenButton = (props) => {
  const navigate = useNavigate();

  const handlButtonClick = (val) => {
    console.log('account******************************', val);
    if (val !== '') {
      navigate(`/connectwallet`);
    }
  };
  const handelBuyToken=()=>{
    
    props.handelBuyToken();
  }
  return (
    <div className="container main_background buytoken_button">
      <img src={Logo} alt="" srcSet="" />
      <Button
        name="Buy Token"
        path="connectmetamask"
        fun={() => {
          handlButtonClick();
          handelBuyToken();
        }}
      />
    </div>
  );
};

export default BuyTokenButton;
