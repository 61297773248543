import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BuyTokenButton from './components/BuyTokenButton/BuyTokenButton';
// import BuyToken from './components/BuyToken/BuyToken';
import ConnectWallet from './components/ConnectWallet/ConnectWallet';
import Dashboard from './components/Dashboard/Dashboard';
import BuyingSuccessful from './components/BuyingSuccessful/BuyingSuccessful';
// import Web3 from 'web3';
import { useState } from 'react';
import { USDTAdress, USDTabi, Dwoodaddress, DwoodAbi } from '../src/data/index';
import Swal from 'sweetalert2';
import { BigNumber } from 'bignumber.js';
import './App.css';
import Admin from './components/admin/Admin';
// import { createAlchemyWeb3 } from '@alch/alchemy-web3';
import Report from './components/admin/Report';
import { ethers } from 'ethers';
const App = () => {
  const [account, setAccount] = useState('');
  const [USDTContract, setUSDTContract] = useState();
  const [DWOODContract, setDWOODContract] = useState();
  const [allotedToken, setAllotedToken] = useState(0);
  const [avaliableForMinting, setAvaliableForMinting] = useState(0);
  const [tokenPurchase, setTokenPurchase] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isRegisterPopup, setIsRegisterPopup] = useState(false);
  const [registerPopupData, setRegisterPopupData] = useState({});
  const [referralsData, setReferralsData] = useState(0);
  const [tokenValueData, setTokenValueData] = useState([]);
  const [buyingSuccessful, setBuyingSuccessful] = useState(false);
  const [totalTokensAllotedTillvalue, setTotalTokensAllotedTillvalue] = useState();
  const [isBuyTokenPopup, setIsBuyTokenPopup] = useState(false);
  //for Admin
  const [adminTotalAllotments, setAdminTotalAllotments] = useState(0);
  const [marketingTeamTotalAllotments, setMarketingTeamTotalAllotments] = useState(0); //for marketing team
  const [developementTotalAllotments, setDevelopementTotalAllotments] = useState(0); //for developement team
  const [strategicTotalAllotments, setStrategicTotalAllotments] = useState(0); //for strategic alliance team
  const [isLogin, setIsLogin] = useState(false);
  const networkId = 137; //mainnet
  // const networkId = 80001  //testnet
  /*Switch to mainnet*/ const networkData = [
    {
      chainId: ethers.utils.hexlify(networkId),
      rpcUrls: ['https://polygon-rpc.com'],

      chainName: 'Polygon Mainnet',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC', // 2-6 characters long
        decimals: 18
      },
      blockExplorerUrls: ['https://polygonscan.com/']
    }
  ];
  // /*Switch to Testnet*/  const networkData = [
  //     {
  //       chainId: ethers.utils.hexlify(networkId),
  //       rpcUrls:[  "https://rpc-mumbai.maticvigil.com"],

  //       chainName: "Matic Testnet",
  //       nativeCurrency: {
  //         name: "MATIC",
  //         symbol: "MATIC", // 2-6 characters long
  //         decimals: 18,
  //       },
  //       blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  //     },
  //   ]

  const switchNetworks = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ethers.utils.hexlify(networkId) }]
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: networkData
          });
        } catch (addError) {
          // handle "add" error
          alert('Please switch network');
          window.location.reload();
        }
      }
      alert('Please switch network');
      window.location.reload();

      // handle other "switch" errors
    }
  };
  const switchProvider = async (provider) => {
    if (provider) {
      if (provider !== window.ethereum) {
        console.error('Not window.ethereum . Do you have multiple wallets installed ?');
      }
      await provider.request({
        method: 'eth_requestAccounts'
      });
      const newProvider = new ethers.providers.Web3Provider(provider);
      // const signer = newProvider.getSigner();
      // const account = await signer.getAddress()
      const network = await newProvider.getNetwork();
      // console.log( network)
      if (network.chainId !== networkId) {
        await switchNetworks();
      }
      const changedProvider = new ethers.providers.Web3Provider(provider);
      return changedProvider;
    }
  };
  const detectProvider = () => {
    let provider;
    if (window.ethereum) {
      provider = window.ethereum;
    } else if (window.web3) {
      provider = window.web3.currentProvider;
    } else {
      window.alert('No ethereum browser detected ! check out metamask');
    }
    return provider;
  };

  let referalsLink = '';
  const refferalLink = (referral) => {
    if (referral.length === 0) {
      referalsLink = '';
    } else {
      referalsLink = referral;
    }
  };
  const handleConnect = async () => {
    try {
      setLoading(true);
      await loadWeb3();
      await loadBlockchainData();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleRegister = async () => {
    setLoading(true);
    await registerloadWeb3();
    await loadBlockchainData();
    setLoading(false);
  };
  const handleCheckRegister = async () => {
    setLoading(true);
    await loadCheckRegisterWeb3();
    setLoading(false);
  };

  const handleCheckRegisterButton = async () => {
    const provider = detectProvider();
    const newProvider = await switchProvider(provider);
    const signer = await newProvider.getSigner();
    const currentaccount = await signer.getAddress();
    const address = currentaccount.toLowerCase();
    const referral = referalsLink;
    const data = {
      accountAddress: address,
      sponserAddress: referral
    };
    const api = 'https://backendtoken.decentrawood.com/users/registerUser';
    // const api = 'http://localhost:5000/users/registerUser';
    try {
      fetch(api, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-type': 'application/json; charset=UTF-8' }
      })
        .then((response) => {
          setIsLogin(true);
          return response.json();
        })
        .then((json) => {
          if (json.message === 'User already register') {
            handleRegister();
          } else {
            setRegisterPopupData({
              referredBy: json.data.referredBy,
              address: json.data.walletAddress
            });
            setIsRegisterPopup(true);
          }
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log('Error', error);
    }
  };
  const handleAspRegisterApi = (accountAddress, referral) => {
    const Aspapi = 'https://myoffice.media/DEOD/api/register';
    const AspData = {
      sponsorid: referral,
      ethaddress: accountAddress,
      password: ''
    };
    try {
      fetch(Aspapi, {
        method: 'POST',
        body: JSON.stringify(AspData),
        headers: { 'Content-type': 'application/json; charset=UTF-8' }
      }).then((response) => {
        if (response.status === 200) {
          console.log('ok');
        } else {
          console.log('notOk');
        }
        console.log('response.json()');
      });
    } catch (error) {
      console.log('error', error);
    }
  };
  const loadWeb3 = async () => {
    const provider = detectProvider();
    const newProvider = await switchProvider(provider);
    const signer = await newProvider.getSigner();
    const currentaccount = await signer.getAddress();
    setAccount(currentaccount.toLowerCase());
  };
  const registerloadWeb3 = async () => {
    const provider = detectProvider();
    const newProvider = await switchProvider(provider);
    const signer = await newProvider.getSigner();
    const currentaccount = await signer.getAddress();
    const address = currentaccount.toLowerCase();
    setAccount(address);
    handleApiFunction(address);
  };

  const loadCheckRegisterWeb3 = async () => {
    const provider = detectProvider();
    const newProvider = await switchProvider(provider);
    const signer = await newProvider.getSigner();
    const currentaccount = await signer.getAddress();
    const address = currentaccount.toLowerCase();
    const api = `https://backendtoken.decentrawood.com/users/buyToken/${address}`;
    // const api = `http://localhost:5000/users/buyToken/${address}`;
    try {
      fetch(api)
        .then((res) => {
          setIsLogin(true);
          return res.json();
        })
        .then((output) => {
          if (output.data === '') {
            Swal.fire({
              position: 'top',
              icon: 'question',
              text: 'Please Register'
            });
          } else {
            handleConnect();
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const loadBlockchainData = async () => {
    try {
      // const web3 = new Web3(window.ethereum);
      const providerr = detectProvider();
      if (providerr) {
        const provider = new ethers.providers.Web3Provider(providerr);
        const signer = provider.getSigner();
        // Load USDTContract contract
        const USDTContractEth = new ethers.Contract(USDTAdress, USDTabi, signer);
        setUSDTContract(USDTContractEth);
        const DwoodContractEth = new ethers.Contract(Dwoodaddress, DwoodAbi, signer);
        setDWOODContract(DwoodContractEth);
      }
    } catch (error) {
      console.log('loadBlockchainData', error);
    }
  };
  const handleApiFunction = (address) => {
    let accountAddress = address;
    let referral = referalsLink;
    const data = {
      accountAddress: accountAddress,
      sponserAddress: referral
    };
    const api = 'https://backendtoken.decentrawood.com/users/createReferalUser';
    // const api = 'http://localhost:5000/users/createReferalUser';
    try {
      fetch(api, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-type': 'application/json; charset=UTF-8' }
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          Swal.fire({
            position: 'top',
            icon: 'success',
            title: `${json.message} Please Login`
          });
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log('Error', error);
    }
    handleAspRegisterApi(accountAddress, referral);
  };
  const purchaseTokens = async (currency, amount, addressReferer, tokensData) => {
    try {
      setLoading(true);
      const bodyData = {
        accountAddress: tokensData.accountAddress,
        sponserAddress: tokensData.referralAddress,
        amountInUSD: tokensData.usdtAmmount,
        amountInDeod: tokensData.deodAmmount.toString(),
        currentDeodRate: tokensData.rate.toString()
      };
      let x;
      x = currency * 10 ** 6;
      let z = new BigNumber(x);
      let a = z.toFixed();
      const txn = await USDTContract.approve(Dwoodaddress, a);
      const receipttxn = await txn.wait();
      if (receipttxn.status !== 1) {
        alert('error message');
      } else {
        const txn1 = await DWOODContract.buyToken(a, addressReferer);
        const receipttxn1 = await txn1.wait();
        console.log('receipttxn1', receipttxn1);
        // const api = 'http://localhost:5000/users/buyDeod';
        const api = 'https://backendtoken.decentrawood.com/users/buyDeod';
        try {
          fetch(api, {
            method: 'POST',
            body: JSON.stringify(bodyData),
            headers: { 'Content-type': 'application/json; charset=UTF-8' }
          })
            .then((response) => {
              return response.json();
            })
            .then((json) => {
              Swal.fire({
                position: 'top',
                icon: 'success',
                title: `${json.message}`
              });
            })
            .catch((err) => console.log(err));
        } catch (error) {
          console.log('Error', error);
        }
      }
      handleAspBuyToken(tokensData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        position: 'top',
        icon: 'question',
        text: `${JSON.stringify(error.reason)}`
      });
    }
  };

  const handleAspBuyToken = (tokensData) => {
    const aspBuyApi = 'https://myoffice.media/DEOD/api/Topup';
    const aspBodyData = {
      accountAddress: tokensData.accountAddress,
      sponserAddress: tokensData.referralAddress,
      amountInUSD: tokensData.usdtAmmount,
      amountInDeod: tokensData.deodAmmount,
      currentDeodRate: tokensData.rate
    };
    try {
      fetch(aspBuyApi, {
        method: 'POST',
        body: JSON.stringify(aspBodyData),
        headers: { 'Content-type': 'application/json; charset=UTF-8' }
      }).then((response) => {
        console.log('aspBuyToken response', response);
        // return response.json(); 5
      });
    } catch (error) {
      console.log(error);
    }
  };
  const loadData = async () => {
    try {
      setLoading(true);
      let balance = await DWOODContract.userMintedBalance(account);
      let mintValue = await DWOODContract.tokensAvaliableForMinting(account);
      let balanceToEth = ethers.utils.formatEther(balance);
      let mintValueToEth = ethers.utils.formatEther(mintValue);
      let totalTokensPurchased = await DWOODContract.totalTokensPurchased(account);
      let totalTokensValue = ethers.utils.formatEther(totalTokensPurchased);
      const handleTokenVale = async () => {
        let newTokenData = [...tokenValueData];
        let userAllotmentCount = await DWOODContract.userAllotmentCount(account);
        // console.log('userAllotmentCount99999', userAllotmentCount);
        for (let j = 0; j < userAllotmentCount; j++) {
          let userAllotmentIds = await DWOODContract.userAllotmentIds(account, j);

          let allotmentData = await DWOODContract.allotments(userAllotmentIds);

          let referedTo = allotmentData.referedTo;
          if (referedTo !== '0x000000000000000000000000000000000000dEaD') {
            const value = allotmentData.tokenAlloted / 10 ** 18;
            newTokenData.push([referedTo, value]);
            // var setTokenData = [...new Set(newTokenData)];
          }
        }
        // console.log([...new Set(newTokenData)])
        setTokenValueData(newTokenData);
      };
      handleTokenVale();
      const handleReferalData = async () => {
        let referalValue = 0;
        let userReferalCount = await DWOODContract.userReferalCount(account);
        for (let i = 0; i < userReferalCount; i++) {
          let userReferalIds = await DWOODContract.userReferalIds(account, i);
          let userReferalIdsData = await DWOODContract.allotments(userReferalIds);
          const tokenAllotedValue = userReferalIdsData.tokenAlloted / 10 ** 18;
          referalValue = referalValue + tokenAllotedValue;
        }
        setReferralsData(referalValue);
      };
      handleReferalData();
      let totalTokensAllotedTillDate = await DWOODContract.totalTokensAllotedTillDate();
      let totalTokensAllotedTillDateValue = ethers.utils.formatEther(totalTokensAllotedTillDate);
      //for Admin
      let totalAllotmentByAdmin = await DWOODContract.totalAllotmentByAdmin();
      let totalAllotmentByAdmin_inWei = ethers.utils.formatEther(totalAllotmentByAdmin);
      setAdminTotalAllotments(totalAllotmentByAdmin_inWei);
      //for Marketing Team
      let totalAllotmentByMarketingTeam = await DWOODContract.totalAllotmentByMarketingTeam();
      let totalAllotmentByMarketingTeam_inWei = ethers.utils.formatEther(
        totalAllotmentByMarketingTeam
      );
      setMarketingTeamTotalAllotments(totalAllotmentByMarketingTeam_inWei);
      //for Developement Team
      let totalAllotmentByDevelopmentTeam = await DWOODContract.totalAllotmentByDevelopmentTeam();
      let totalAllotmentByDevelopmentTeam_inWei = ethers.utils.formatEther(
        totalAllotmentByDevelopmentTeam
      );
      setDevelopementTotalAllotments(totalAllotmentByDevelopmentTeam_inWei);
      //Strategic Alliance Team
      let totalAllotmentByStrategicAllianceTeam =
        await DWOODContract.totalAllotmentByStrategicAllianceTeam();
      let totalAllotmentByStrategicAllianceTeam_inWei = ethers.utils.formatEther(
        totalAllotmentByStrategicAllianceTeam
      );
      setStrategicTotalAllotments(totalAllotmentByStrategicAllianceTeam_inWei);
      setTotalTokensAllotedTillvalue(totalTokensAllotedTillDateValue);
      setAllotedToken(balanceToEth);
      setAvaliableForMinting(mintValueToEth);
      setTokenPurchase(totalTokensValue);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAllotmentByAdmin = async (transferAddress, transferAmount) => {
    try {
      setLoading(true);
      let x;
      x = transferAmount * 10 ** 18;
      let z = new BigNumber(x);
      let a = z.toFixed();
      const tx = await DWOODContract.allotmentByAdmin(transferAddress, a);
      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        alert('error');
        setLoading(false);
      } else {
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'transaction successful'
        });
        // alert('transaction successful')
        setLoading(false);
        window.location.reload(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        position: 'top',
        icon: 'question',
        text: `${'Catch:'}${error}`
      });
    }
  };
  const handleAllotmentByDevelopmentTeam = async (transferAddress, transferAmount) => {
    try {
      setLoading(true);
      let x;
      x = transferAmount * 10 ** 18;
      let z = new BigNumber(x);
      let a = z.toFixed();
      const tx = await DWOODContract.allotmentByDevelopmentTeam(transferAddress, a);
      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        alert('error');
        setLoading(false);
      } else {
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'transaction successful'
        });
        // alert('transaction successful')
        setLoading(false);
        window.location.reload(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        position: 'top',
        icon: 'question',
        text: `${'Catch:'}${error}`
      });
    }
  };

  const handleAllotmentByMarketingTeam = async (transferAddress, transferAmount) => {
    try {
      setLoading(true);
      let x;
      x = transferAmount * 10 ** 18;
      let z = new BigNumber(x);
      let a = z.toFixed();
      const tx = await DWOODContract.allotmentByMarketingTeam(transferAddress, a);
      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        alert('error');
        setLoading(false);
      } else {
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'transaction successful'
        });
        // alert('transaction successful')
        setLoading(false);
        window.location.reload(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        position: 'top',
        icon: 'question',
        text: `${'Catch:'}${error}`
      });
    }
  };
  const handleAllotmentByStrategicAllianceTeam = async (transferAddress, transferAmount) => {
    try {
      setLoading(true);
      let x;
      x = transferAmount * 10 ** 18;
      let z = new BigNumber(x);
      let a = z.toFixed();
      const tx = await DWOODContract.allotmentByStrategicAllianceTeam(transferAddress, a);
      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        alert('error');
        setLoading(false);
      } else {
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'transaction successful'
        });
        // alert('transaction successful')
        setLoading(false);
        window.location.reload(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        position: 'top',
        icon: 'question',
        text: `${'Catch:'}${error}`
      });
    }
  };
  const handleClaimToken = async () => {
    setLoading(true);
    try {
      const tx = await DWOODContract.claimTokens();
      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        alert('error');
        setLoading(false);
      } else {
        await loadData();
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'transaction successful'
        });
        // alert('Clamied tokens Successful')
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/buytokenbutton" element={<BuyTokenButton account={account} />} />
        <Route
          exact
          path="/"
          element={
            <ConnectWallet
              handleConnect={handleConnect}
              handleRegister={handleRegister}
              handleCheckRegisterButton={handleCheckRegisterButton}
              handleCheckRegister={handleCheckRegister}
              account={account}
              loading={loading}
              refferalLink={refferalLink}
              isRegisterPopup={isRegisterPopup}
              setIsRegisterPopup={setIsRegisterPopup}
              registerPopupData={registerPopupData}
              setIsLogin={setIsLogin}
            />
          }
        />
        <Route
          exact
          path="/dashboard"
          element={
            <Dashboard
              DWOODContract={DWOODContract}
              account={account}
              allotedToken={allotedToken}
              handleConnect={handleConnect}
              avaliableForMinting={avaliableForMinting}
              tokenPurchase={tokenPurchase}
              handleClaimToken={handleClaimToken}
              referralsData={referralsData}
              tokenValueData={tokenValueData}
              setLoading={setLoading}
              loadWeb3={loadWeb3}
              loading={loading}
              // referrals={referrals}
              // tokenValeData={tokenValeData}
              // referralsData={referralsData}
              buyingSuccessful={buyingSuccessful}
              setBuyingSuccessful={setBuyingSuccessful}
              totalTokensAllotedTillvalue={totalTokensAllotedTillvalue}
              purchaseTokens={purchaseTokens}
              isBuyTokenPopup={isBuyTokenPopup}
              setIsBuyTokenPopup={setIsBuyTokenPopup}
              isLogin={isLogin}
              loadData={loadData}
              loadBlockchainData={loadBlockchainData}
              setDWOODContract={setDWOODContract}
            />
          }
        />
        <Route exact path="/buyingsuccessful" element={<BuyingSuccessful />} />
        <Route
          exact
          path="/admin"
          element={
            <Admin
              account={account}
              handleConnect={handleConnect}
              loading={loading}
              adminTotalAllotments={adminTotalAllotments}
              marketingTeamTotalAllotments={marketingTeamTotalAllotments}
              developementTotalAllotments={developementTotalAllotments}
              strategicTotalAllotments={strategicTotalAllotments}
              handleAllotmentByAdmin={handleAllotmentByAdmin}
              handleAllotmentByMarketingTeam={handleAllotmentByMarketingTeam}
              handleAllotmentByStrategicAllianceTeam={handleAllotmentByStrategicAllianceTeam}
              handleAllotmentByDevelopmentTeam={handleAllotmentByDevelopmentTeam}
            />
          }
        />
        <Route exact path="/admin/report" element={<Report />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
