import React from 'react';
import Logo from '../../assets/img/Logo.png';
import Man from '../../assets/img/Man.png';
import Button from '../../UI/Button/Button';
import './buyingsuccessful.css';

const BuyingSuccessful = () => {
  return (
    <div className="container main_background buying_successful">
      <img src={Logo} alt="" srcSet="" />
      {/* <p>DECENTRAWOOD</p> */}
      <img src={Man} alt="" srcSet="" className="man_image" />
      <Button name="Buying Successful" path="" />
    </div>
  );
};

export default BuyingSuccessful;
