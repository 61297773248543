import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../UI/Button/Button';
import Logo from '../../assets/img/Logo.png';
import './dashboard.css';
// import { useNavigate } from 'react-router-dom';
import BuyToken from '../BuyToken/BuyToken';
import Swal from 'sweetalert2';

export const Header = (props) => {
  const navigate = useNavigate();
  const [sponserAddress, setsponserAddress] = useState('');

  const handleAPIfunction = () => {
    const api = `https://backendtoken.decentrawood.com/users/buyToken/${props.account}`;
    // const api = `http://localhost:5000/users/buyToken/${props.account}`;

    try {
      fetch(api)
        .then((res) => {
          return res.json();
        })
        .then((output) => {
          setsponserAddress(output.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handlButtonClick = () => {
    handleAPIfunction();
    props.setIsBuyTokenPopup(true);
  };
  const handlLogout = () => {
    Swal.fire({
      position: 'top',
      icon: 'success',
      text: 'Please Disconnect from your crypto wallet',
      showConfirmButton: false,
      timer: 2000
    });
    setTimeout(() => {
      navigate('/');
      window.location.reload(false);
    }, 2000);
  };

  const closeButtonClick = () => {
    props.setIsBuyTokenPopup(false);
    navigate('/dashboard');
    props.loadData();
  };

  useEffect(() => {
    if (props.referralAccount != '') {
      props.setIsBuyTokenPopup(true);
    }
  }, [props.referralAccount]);

  return (
    <header className="header">
      <div className="logo">
        {props.isBuyTokenPopup ? (
          <BuyToken
            account={props.account}
            purchaseTokens={props.purchaseTokens}
            handleConnect={props.handleConnect}
            loading={props.loading}
            setLoading={props.setLoading}
            loadWeb3={props.loadWeb3}
            buyingSuccessful={props.buyingSuccessful}
            setBuyingSuccessful={props.setBuyingSuccessful}
            totalTokensAllotedTillvalue={props.totalTokensAllotedTillvalue}
            referralAccount={props.referralAccount}
            rate={props.rate}
            purchaseTokensWithoutReferal={props.purchaseTokensWithoutReferal}
            buyTokenForm={props.buyTokenForm}
            sponserAddress={sponserAddress}
          />
        ) : (
          ''
        )}
        {props.isBuyTokenPopup ? (
          <button className="closeBtn" onClick={closeButtonClick}>
            X
          </button>
        ) : (
          ''
        )}
        <img src={Logo} alt="" srcSet="" className="logo_img" />
        {/* <span>DECENTRAWOOD</span> */}
      </div>
      <div className="header-Container">
        <div className="buy-btn">
          <Button name="Buy Token" path="buytoken" fun={handlButtonClick} />
        </div>
        <div className="logout-btn">
          <Button name="Disconnect" fun={handlLogout} />
        </div>
      </div>
    </header>
  );
};
