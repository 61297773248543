import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../BuyToken/buytoken.css';
import './report.css'
const Report = () => {
  const navigate = useNavigate()
  const [searchInput, setSearchInput] = useState('')
  // const [filteredResults, setFilteredResults] = useState([]);
  const [allData, setAllData] = useState([])
  const backBtn = () => {
    navigate('/admin')
  }
  // const searchItems = (searchValue) => {
  //   setSearchInput(searchValue)
  //   if (searchInput !== '') {
  //     const filteredData = allData.filter((item) => {
  //       return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
  //     })
  //     setFilteredResults(filteredData)
  //   }
  //   else {
  //     setFilteredResults(allData)
  //   }
  // }
  const fetchAndLog = async () => {
    try {
      const api = 'https://backendtoken.decentrawood.com/'
      // const api = 'http://localhost:5000'
      const response = await fetch(`${api}/users/getUserSponser`);
      const json = await response.json();
      // just log ‘json’
      const newData = await json.data
      setAllData(newData)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchAndLog()
  }, [])
  return (
    <>
      <div className='container main_background'>

        <div className='wrap' >
          <button onClick={backBtn} className="backBtn">
            <i className="fa-solid fa-arrow-left"></i>
          </button>
          <div className="search">
            <input type="text" className="searchTerm" placeholder="Search Address" onChange={(e) => setSearchInput(e.target.value)} />
            <button type="submit" className="searchButton">
              <i className="fa fa-search"></i>
            </button>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">Address</th>
                <th scope="col">Referral address</th>
              </tr>
            </thead>
            <tbody>
              {/* {searchInput.length > 0 ? (filteredResults.length > 0 ? (
              filteredResults.map((value, key) => {
                return (
                  <>
                    <tr id={key} >
                      <td>{value.accountAddress}</td>
                      <td>{value.sponserAddress}</td>
                    </tr>
                  </>
                )
              })
            ) : (<tr>
              <td>No results found </td>
            </tr>)) : (
              allData.map((value, key) => {
                return (
                  <>
                    <tr id={key} >
                      <td>{value.accountAddress}</td>
                      <td>{value.sponserAddress}</td>
                    </tr>
                  </>
                )
              })
            )} */}
              {allData ? allData.filter(item => {
                if (searchInput === '') {
                  return item;
                } else if (item.accountAddress.toLowerCase().includes(searchInput.toLowerCase())) {
                  return item;
                } else if (item.sponserAddress.toLowerCase().includes(searchInput.toLowerCase())){
                  return item;
                }
              }).map(item => {
               
                return (
                  <>
                    <tr >
                      <td>{item.accountAddress}</td>
                      <td>{item.sponserAddress}</td>
                    </tr>
                  </>
                )
              
              }
              ) : (
              <><tr>
                <td>No results found</td>
              </tr>
              </>
              )
              }
            </tbody>
          </table>
        </div>

      </div>
    </>
  )
}
export default Report