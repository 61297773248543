import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Loading from '../Loading/Loading';
import Button from '../../UI/Button/Button';
import './buytoken.css';
import BuyingSuccessful from '../BuyingSuccessful/BuyingSuccessful';
import Logo from '../../assets/img/Logo.png';

const BuyToken = (props) => {
  const [amount, setAmmount] = useState('');
  const [currency, setCurrency] = useState('');

  // const totalTokensAllotedValue = props.totalTokensAllotedTillvalue;
  const handlecalculation = (e) => {
    const rate = props.rate;
    const Ammount = e.target.value;
    setAmmount(Ammount * rate);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currency < 100) {
      Swal.fire({
        position: 'top',
        icon: 'question',
        text: 'Amount must be greater than 100 USDT'
      });
      // alert('Amount must be greater than 100 USDT');
    } else {
      const tokensData = {
        accountAddress: props.account,
        referralAddress: props.sponserAddress,
        usdtAmmount: currency,
        deodAmmount: amount,
        rate: props.rate
      };
      // console.log('buyTokens Data', tokensData);
      props.purchaseTokens(currency, amount, props.sponserAddress, tokensData);
    }
  };
  const start_and_end = (refferal) => {
    if (refferal.length > 40) {
      return refferal.substr(0, 15) + '....' + refferal.substr(refferal.length - 15, refferal.length);
    }
    return refferal;
  };
  useEffect(() => {
    // console.log(props.account);
    props.handleConnect();
  }, [props.account]);

  return (
    <>
      {props.buyingSuccessful ? (
        <BuyingSuccessful />
      ) : (
        <>
          <Loading loading={props.loading} />
          <div className="container buytoken">
            {props.account === '' ? (
              <>
                <div style={{ marginTop: '50px' }}></div>
                <Button name="Connect Wallet" fun={props.handleConnect} />
              </>
            ) : (
              <>
                <form onSubmit={handleSubmit} className="butTokenForm">
                  {/* <p className="logo_text">DECENTRAWOOD</p> */}
                  <img src={Logo} alt="" className="formLogo" />
                  <div className="box">
                    <p>Connected Wallet</p>
                    <input type="text" placeholder={start_and_end(props.account)} disabled />
                  </div>
                  <div className="box">
                    <p>Referral Wallet</p>
                    <input type="text" placeholder={start_and_end(props.sponserAddress)} disabled />
                  </div>
                  <div className="box_one">
                    <input
                      type="number"
                      min={100}
                      placeholder="Please Enter Amount"
                      value={currency}
                      onChange={(e) => {
                        setCurrency(e.target.value);
                        handlecalculation(e);
                      }}
                    />

                    <div className="select_currency">USDT</div>
                  </div>
                  <div className="amt-ms">
                    {currency < 100 ? 'Amount must be greater than 100 USDT' : null}
                  </div>
                  <div className="box_two">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p>Amount of DEOD Token</p>
                      <p style={{ textAlign: 'end' }}>(1 USDT = {props.rate} DEOD)</p>
                    </div>
                    <input type="number" placeholder="0.00" value={amount} disabled />
                  </div>
                  <Button name="Submit" />
                </form>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default BuyToken;
