import React, { useEffect, useState } from 'react';
import '../Loading/style.css';

const Loading = (props) => {
  const [loadingVisiblity, setLoadingVisiblity] = useState('none');
  // console.log(props);

  useEffect(() => {
    // console.log('state', props.loading);
    if (props.loading) {
      // console.log(loadingVisiblity);
      setLoadingVisiblity('flex');
    } else {
      // console.log(loadingVisiblity);
      setLoadingVisiblity('none');
    }
  }, [props.loading]);

  return (
    <div className="loading" style={{ display: loadingVisiblity }}>
      <p>Loading</p>
    </div>
  );
};

export default Loading;
