import React, { useEffect, useState } from 'react';
import Button from '../../UI/Button/Button';
import Loading from '../Loading/Loading';
import '../BuyToken/buytoken.css';
import { useNavigate } from 'react-router-dom';

const ADMINS = [
  '0x418084C5E6a34790c954D45Fc8eB8524a540E860' /*main1*/,
  // '0x3e2E7E6c8802821147c00742b2aA64167BE757b0',/*test1 */
  '0xde5222980234799300DD7f6D324E10435D1bD692' /*main2*/,
  // '0xCe4e22E7A4f39B9C6c10B2dfb3F9430B06120DBC',/*test2 */
  '0xF9dAF9cC3835b78591f09b22FDC6F552D9aE6E76' /*main3*/,
  // '0x1025Fe18F1eaFB52E09D74f65323026f774a8a4d',/*test3*/
  '0xd4E7371E22F1DdEca24b797473F6CBCfB0CA4BB0' /*main4*/
  // '0x0ade893921c9f963869E1D6C501E5d3E1004Cb96',/*test4*/
];

const Admin = (props) => {
  const [transferAmount, setTransferAmount] = useState('');
  const [transferAddress, setTransferAddress] = useState('');
  const [adminAllotment, setAdminAllotment] = useState(0);
  const navigate = useNavigate();
  const handleReportClick = () => {
    navigate('/admin/report');
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.account === ADMINS[0].toLocaleLowerCase()) {
      props.handleAllotmentByAdmin(transferAddress, transferAmount);
    } else if (props.account === ADMINS[1].toLocaleLowerCase()) {
      props.handleAllotmentByMarketingTeam(transferAddress, transferAmount);
    } else if (props.account === ADMINS[2].toLocaleLowerCase()) {
      props.handleAllotmentByDevelopmentTeam(transferAddress, transferAmount);
    } else if (props.account === ADMINS[3].toLocaleLowerCase()) {
      props.handleAllotmentByStrategicAllianceTeam(transferAddress, transferAmount);
    }
  };

  useEffect(() => {
    if (props.account === ADMINS[0].toLocaleLowerCase()) {
      setAdminAllotment(props.adminTotalAllotments);
    } else if (props.account === ADMINS[1].toLocaleLowerCase()) {
      setAdminAllotment(props.marketingTeamTotalAllotments);
    } else if (props.account === ADMINS[2].toLocaleLowerCase()) {
      setAdminAllotment(props.developementTotalAllotments);
    } else if (props.account === ADMINS[3].toLocaleLowerCase()) {
      setAdminAllotment(props.strategicTotalAllotments);
    }
  }, [
    props.account,
    props.adminTotalAllotments,
    props.marketingTeamTotalAllotments,
    props.strategicTotalAllotments
  ]);

  return (
    <div className="container main_background">
      <Loading loading={props.loading} />
      {props.account === '' ? (
        <Button name="Connect Wallet" fun={props.handleConnect} />
      ) : (
        <>
          <div style={{ position: 'absolute', top: '5%', right: '5%' }}>
            <Button name="Report" fun={handleReportClick} />
          </div>
          <form onSubmit={handleSubmit} className="adminForm">
            {/* <p className="logo_text">DECENTRAWOOD ADMIN</p> */}
            <div className="box">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Connected Wallet</p>
                <p>(Total Allotment: {adminAllotment})</p>
              </div>
              <input type="text" placeholder={props.account} disabled />
            </div>
            <div className="box">
              <input
                type="text"
                placeholder="Enter Address"
                onChange={(e) => setTransferAddress(e.target.value)}
                value={transferAddress}
                required
              />
            </div>
            <div className="box_two">
              <p>Transfer Amount</p>
              <input
                type="number"
                min={0}
                placeholder="0.00"
                onChange={(e) => setTransferAmount(e.target.value)}
                value={transferAmount}
                required
              />
            </div>
            <Button name="submit" />
          </form>
        </>
      )}
    </div>
  );
};

export default Admin;
